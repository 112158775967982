import { MenuIntemInterface } from './interfaces/menu-item.interface';
import { MenuCollapseInterface } from './interfaces/menu-collapse.interface';
import { MenuGroupInterface } from './interfaces/menu-group.interface';

export class AppNavigationModel {
	public model: any[];

	constructor() {
		this.model = [
			{
				id: 'applications',
				title: 'Applications',
				translate: 'Benefits System',
				type: 'group',
				icon: 'apps',
				children: [
					{
						id: 'home',
						title: 'Home',
						translate: 'MENU.HOME',
						type: 'item',
						url: '/main/home',
						icon: 'home',
					},
					{
						id: 'catalog',
						title: 'Catalogs',
						translate: 'MENU.CATALOGS',
						type: 'collapse',
						icon: 'workspaces_filled',
						children: [
							{
								id: 'sites',
								title: 'Sites',
								translate: 'MENU.SITES_MENU',
								type: 'item',
								url: '/main/sites',
								icon: 'business',
							},
							{
								id: 'pay-grades',
								title: 'Pay Grades',
								translate: 'MENU.PAY_GRADES_MENU',
								type: 'item',
								url: '/main/paygrades',
								icon: 'account_balance',
							},
							{
								id: 'pay-grades',
								title: 'Pay Rate Types',
								translate: 'MENU.PAY_RATE_TYPES_MENU',
								type: 'item',
								url: '/main/payratetypes',
								icon: 'account_balance_wallet',
							},
							{
								id: 'job-categories',
								title: 'Job Categories',
								translate: 'MENU.JOB_CATEGORIES_MENU',
								type: 'item',
								url: '/main/jobcategories',
								icon: 'work_outline',
							},
							{
								id: 'employee-type',
								title: 'Employee Types',
								translate: 'MENU.EMPLOYEE_TYPES_MENU',
								type: 'item',
								url: '/main/employeetypes',
								icon: 'person',
							},
							{
								id: 'external-links',
								title: 'External Links',
								translate: 'MENU.EXTERNAL_LINKS_MENU',
								type: 'item',
								url: '/main/externallinks',
								icon: 'launch',
							},
						],
					},
					{
						id: 'users-&-roles',
						title: 'Users & Roles',
						translate: 'MENU.USERS_AND_ROLES',
						type: 'collapse',
						icon: 'group',
						children: [
							{
								id: 'users',
								title: 'Users',
								translate: 'MENU.USERS_MENU',
								type: 'item',
								url: '/main/employees',
								icon: 'account_circle',
							},
							{
								id: 'roles',
								title: 'Roles',
								translate: 'MENU.ROLES_MENU',
								type: 'item',
								url: '/main/roles',
								icon: 'assignment_ind',
							},
						],
					},
					{
						id: 'configurations',
						title: 'Configurations',
						translate: 'MENU.CONFIGURATIONS_MENU',
						type: 'item',
						url: '/main/configurations',
						icon: 'settings',
					},
					{
						id: 'reports',
						title: 'Reports',
						translate: 'MENU.REPORTS',
						type: 'item',
						url: '/main/reports',
						icon: 'pie_chart',
					},
					{
						id: 'about',
            			title: 'Abaout',
            			translate: 'MENU.About',
            			type: 'collapse',
            			icon: 'info_outline',
            			children: [
							{
								title: 'Contact',
								translate: 'MENU.Contact',
								type: 'item',
								url: '/main/contact',
								icon: 'contact_mail_outlined',
							},
							{
								title: 'Last releases',
								translate: 'MENU.Last_releases',
								type: 'item',
								url: '/main/lastreleases',
								icon: 'new_releases_outlined',
							},
						],
					},

				],
			},
		];
	}
}
