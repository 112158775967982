import { Injectable } from '@angular/core';
import { RestCallService } from '../../../../core/services/rest-call/rest-call.service';
import { HttpClient } from '@angular/common/http';
import { TranslateApplicationService } from '../../../../core/translate/translate-application-service';
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from 'src/app/core/services/notification/notification.service';
import { IRoles } from '../Interfaces/IRoles';
import { GeneralServices } from '../services/general-services';
import { IScreens } from '../Interfaces/IScreens';
import { IRolesScreens } from '../Interfaces/IRolesScreens';
import { ThrowStmt } from '@angular/compiler';

@Injectable({
	providedIn: 'root',
})
export class RolesService extends RestCallService {
	public dataToSend: IRoles = {
		pk_role: 0,
		role: '',
		creation_date: new Date(),
		str_creation_date: '',
		fk_employee_creator: 1,
		last_updated: new Date(),
		str_last_updated: '',
		fk_employee_updater: 1,
		fk_record_status: 1,
		employee_creator_name: '',
		employee_updater_name: '',
		username: '',
		record_status_name: '',
		check: false,
		fk_site_logged_user: 0,
		editable: true,
	};
	public rolesScreens: IRolesScreens = {
		fk_role: 0,
		fk_screen: 0,
		fk_site: 0,
		username: '',
		screen_in_angular: '',
		fk_record_status: 0,
		orders: [],
		fk_site_logged_user: 0,
	};
	i: number;
	public role: IRoles = {
		pk_role: 0,
		role: '',
		creation_date: new Date(),
		str_creation_date: '',
		fk_employee_creator: 1,
		last_updated: new Date(),
		str_last_updated: '',
		fk_employee_updater: 1,
		fk_record_status: 1,
		employee_creator_name: '',
		employee_updater_name: '',
		username: '',
		record_status_name: '',
		check: false,
		fk_site_logged_user: 0,
		editable: true,
	};

	resultCreate: any;

	constructor(
		public httpClient: HttpClient,
		private generalServices: GeneralServices,
		public toastr: ToastrService,
		public notificationService: NotificationService,
		public translate: TranslateApplicationService,
	) {
		super(
			httpClient,
			'API_Benefits',
			toastr,
			notificationService,
			translate,
		);
	}

	public GetList() {
		this.generalServices.ValidateCognito();
		this.dataToSend.fk_site_logged_user = parseInt(
			localStorage.getItem('fksite'),
		);
		return this.post<IRoles[]>('/api/Roles/GetRoles', this.dataToSend);
	}

	public GetRolesList() {
		this.generalServices.ValidateCognito();
		this.dataToSend.fk_site_logged_user = parseInt(
			localStorage.getItem('fksite'),
		);
		return this.post<IRoles[]>('/api/Roles/GetRolesList', this.dataToSend);
	}

	public GetScreens(data: any) {
		this.generalServices.ValidateCognito();
		this.dataToSend.pk_role = data.pkrole;
		this.dataToSend.fk_site_logged_user = parseInt(
			localStorage.getItem('fksite'),
		);
		return this.post<IScreens[]>('/api/Roles/GetScreens', this.dataToSend);
	}

	public CreateRole(data: any) {
		this.generalServices.ValidateCognito();
		this.dataToSend.role = data.txtRole;
		this.dataToSend.fk_site_logged_user = parseInt(
			localStorage.getItem('fksite'),
		);
		return this.post<IRoles>('/api/Roles/CreateRole', this.dataToSend);
	}

	public UpdateRole(data: any) {
		this.generalServices.ValidateCognito();
		this.dataToSend.pk_role = data.pkrole;
		this.dataToSend.role = data.txtRole;
		this.dataToSend.fk_site_logged_user = parseInt(
			localStorage.getItem('fksite'),
		);
		return this.put<IRoles>('/api/Roles/UpdateRole', this.dataToSend);
	}

	public AddRolesScreens(data: any, dataScreens: any) {
		this.generalServices.ValidateCognito();
		this.rolesScreens.fk_role = data.pk_role;
		this.rolesScreens.orders = dataScreens.chkOrders;
		this.rolesScreens.fk_site_logged_user = parseInt(
			localStorage.getItem('fksite'),
		);
		return this.post<IRolesScreens>(
			'/api/Roles/CreateRolesScreens',
			this.rolesScreens,
		);
	}

	public ChangeStatusCategory(data: any) {
		this.generalServices.ValidateCognito();
		this.rolesScreens.fk_site_logged_user = parseInt(
			localStorage.getItem('fksite'),
		);
		this.rolesScreens.fk_role = data.pk_role;
		this.rolesScreens.fk_record_status = data.fk_record_status;
		return this.post('/api/Roles/changeStatusCategory', this.rolesScreens);
	}

	public GetScreensByUsername() {
		this.generalServices.ValidateCognito();
		this.rolesScreens.fk_site_logged_user = parseInt(
			localStorage.getItem('fksite'),
		);
		return this.post<IScreens[]>(
			'/api/Roles/GetScreensByUsername',
			this.rolesScreens,
		);
	}

	public EmployeeHasPermissionToScreen(screenInAngular: string) {
		this.generalServices.ValidateCognito();
		this.rolesScreens.fk_site_logged_user = parseInt(
			localStorage.getItem('fksite'),
		);
		this.rolesScreens.screen_in_angular = screenInAngular;
		return this.post<IScreens[]>(
			'/api/Roles/EmployeeHasPermissionToScreen',
			this.rolesScreens,
		);
	}
}
