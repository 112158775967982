import { Component, OnInit, Output, EventEmitter,Input } from '@angular/core';

@Component({
	selector: 'button-process',
	templateUrl: './button-process.component.html',
	styleUrls: ['./button-process.component.scss'],
})
export class ButtonProcessComponent implements OnInit {
	@Output() eventClick = new EventEmitter<any>();
	@Input() disable: boolean;

	constructor() 
	{
		// This is intentional
	}

	ngOnInit(): void 
	{
		// This is intentional
	}

	process() {
		this.eventClick.emit();
	}
}
