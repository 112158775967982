import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '../services/translate-service';
import { Observable } from 'rxjs';
import { Application } from './models/application';
import { map } from 'rxjs/operators';
import { AppsInfo } from './models/app-info';
import { RestCallOriginService } from '../services/rest-call/rest-call-origin.service';

@Injectable({
  providedIn: 'root'
})
export class ApplicationInfoService extends RestCallOriginService {
  languages: Array<any> = [];

  constructor(
    public httpClient: HttpClient,
    public translate: TranslateService) {
    super(httpClient, 'TranslateService', null, null, translate);
  }

  private getAppInfo() {
    return this.get<any>('applications-info');
  }

  public getApplicationByName(): Observable<Application> {
    return this.getAppInfo().pipe(
      map(model => {
        if (model.success) {
          let appsInfo: AppsInfo = JSON.parse(model.data);
          return appsInfo.applications.find(p => p.application == window["applicationId"]);
        }
      }),
    );
  }

  public loadLanguages(): Promise<boolean> {
    let promise = new Promise<boolean>((resolve, reject) => {
      resolve(true);
    });
    return promise;
  }
}
