import { Injectable } from '@angular/core';
import { RestCallService } from '../../../../core/services/rest-call/rest-call.service';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from 'src/app/core/services/notification/notification.service';
import { ISites } from '../Interfaces/ISites';
import { TranslateApplicationService } from '../../../../core/translate/translate-application-service';
import { GeneralServices } from '../services/general-services';
import { IBenefit } from '../Interfaces/IBenefit';

@Injectable({
	providedIn: 'root',
})
export class SitesService extends RestCallService {
	public dataToSend: ISites = {
		pk_site: 0,
		site: '',
		creation_date: new Date(),
		str_creation_date: '',
		fk_employee_creator: 1,
		last_updated: new Date(),
		str_last_updated: '',
		fk_employee_updater: 1,
		fk_record_status: 1,
		employee_creator_name: '',
		employee_updater_name: '',
		username: '',
		record_status_name: '',
		fk_employee: 0,
		check: false,
		building: '',
		fk_building: 0,
		level: 0,
		fk_site: 0,
		pk_building: 0,
		active: false,
		fk_site_logged_user: 0,
		timezone: '',
		fk_language: 0,
		language: '',
	};

	public benefitsDataToSend: IBenefit = {
		pk_benefit: 0,
		benefit: '',
		elegibility: '',
		restriction: '',
		contact: '',
		coverture_details: '',
		benefit_process: '',
		politics: '',
		fk_site: 0,
		site: '',
		fk_pay_grade: 0,
		pay_grade: '',
		fk_pay_rate_type: 0,
		pay_rate_type: '',
		fk_job_category: 0,
		job_Category: '',
		fk_employee_type: 0,
		employee_type: '',
		creation_date: new Date(),
		last_update: new Date(),
		fk_record_status: 0,
		file_benefits_process: '',
		file_coverture_details: '',
		file_politicts: '',
		fk_external_links: '',
		img_Icon: '',
		img_Icon_temp: '',
		img_banner: '',
		type: '',
		external_link_description: '',
		external_link_image_url: '',
		external_link_link: '',
		external_link_name: '',
		username: '',
		external_links: '',
		fk_site_logged_user: 0,
		type_of_file: '',
		fk_user_creator: 0,
		fk_user_updater: 0,
	};

	constructor(
		public httpClient: HttpClient,
		private generalServices: GeneralServices,
		public toastr: ToastrService,
		public notificationService: NotificationService,
		public translate: TranslateApplicationService,
	) {
		super(
			httpClient,
			'API_Benefits',
			toastr,
			notificationService,
			translate,
		);
	}

	public GetList() {
		this.generalServices.ValidateCognito();
		this.dataToSend.fk_site_logged_user = parseInt(
			localStorage.getItem('fksite'),
		);
		return this.post<ISites[]>('/api/Sites/GetSites', this.dataToSend);
	}

	public ChangeStatusCategory(data: any) {
		this.generalServices.ValidateCognito();
		this.dataToSend.pk_site = data.pk_site;
		this.dataToSend.language = data.language;
		this.dataToSend.timezone = data.timezone;
		this.dataToSend.fk_record_status = data.fk_record_status;
		this.dataToSend.fk_site_logged_user = parseInt(
			localStorage.getItem('fksite'),
		);

		return this.put('/api/Sites/changeStatusCategory', this.dataToSend);
	}

	public ChangeStatus(data: any, pk_benefit: any) {
		this.generalServices.ValidateCognito();
		this.benefitsDataToSend.fk_site = data.pk_site;
		this.benefitsDataToSend.pk_benefit = pk_benefit;
		this.benefitsDataToSend.fk_record_status = data.fkrecordstatus;
		this.benefitsDataToSend.fk_site_logged_user = parseInt(
			localStorage.getItem('fksite'),
		);
		return this.put('/api/Sites/changeStatus', this.benefitsDataToSend);
	}

	public GetSitesByUsername() {
		this.generalServices.ValidateCognito();
		return this.post<ISites[]>(
			'/api/Sites/GetSitesByUsername',
			this.dataToSend,
		);
	}

	public GetSitesByUsernameAndBenefit(data: any) {
		this.generalServices.ValidateCognito();
		this.dataToSend.fk_site_logged_user = parseInt(
			localStorage.getItem('fksite'),
		);
		this.dataToSend.pk_site = data;
		return this.post<ISites[]>(
			'/api/Sites/GetSitesByUsernameAndBenefit',
			this.dataToSend,
		);
	}

	public GetSitesByBenefit(data: any) {
		this.generalServices.ValidateCognito();
		this.benefitsDataToSend.fk_site_logged_user = parseInt(
			localStorage.getItem('fksite'),
		);
		this.benefitsDataToSend.pk_benefit = data;
		return this.post<ISites[]>(
			'/api/Sites/GetSitesByBenefit',
			this.benefitsDataToSend,
		);
	}

	public IsActiveSite() {
		this.generalServices.ValidateCognito();
		this.dataToSend.fk_site_logged_user = parseInt(
			localStorage.getItem('fksite'),
		);
		return this.post<ISites>('/api/Sites/isActive', this.dataToSend);
	}

	public GetTimeZones() {
		this.generalServices.ValidateCognito();
		this.dataToSend.fk_site_logged_user = parseInt(
			localStorage.getItem('fksite'),
		);
		return this.post<any[]>('/api/Sites/GetTimeZones', this.dataToSend);
	}

	public GetLanguages() {
		this.generalServices.ValidateCognito();
		this.dataToSend.fk_site_logged_user = parseInt(
			localStorage.getItem('fksite'),
		);
		return this.post<any[]>('/api/Sites/GetLanguages', this.dataToSend);
	}

	public EditTimezoneAndLanguaje(data: any) {
		this.generalServices.ValidateCognito();
		this.dataToSend.pk_site = data.pk_site;
		this.dataToSend.timezone = data.cbTimeZone;
		this.dataToSend.language = data.cbLanguages;
		return this.post<any[]>(
			'/api/Sites/EditTimezoneAndLanguaje',
			this.dataToSend,
		);
	}

	public GetCurrentSite(data: any) {
		this.generalServices.ValidateCognito();
		this.dataToSend.pk_site = data;
		this.dataToSend.fk_site_logged_user = parseInt(
			localStorage.getItem('fksite'),
		);
		return this.post<ISites[]>(
			'/api/Sites/GetCurrentSite',
			this.dataToSend,
		);
	}
}
