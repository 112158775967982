import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'button-disable',
	templateUrl: './button-disable.component.html',
	styleUrls: ['./button-disable.component.scss'],
})
export class ButtonDisableComponent implements OnInit {
	@Output() eventClick = new EventEmitter<any>();

	constructor() 
	{
		// This is intentional
	}

	ngOnInit(): void 
	{
		// This is intentional
	}

	disable() {
		this.eventClick.emit();
	}
}
