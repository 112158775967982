import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
	selector: 'button-multiselect',
	templateUrl: './button-multiselect.component.html'
})
export class ButtonMultiselectComponent implements OnInit {
	@Output() eventClick = new EventEmitter<any>();
	@Input() disable: boolean;
	@Input() caption: string = "";

	constructor() 
	{
		// This is intentional
	}

	ngOnInit(): void 
	{
		// This is intentional
	}

}
