import { Component, OnInit, Output, EventEmitter,Input } from '@angular/core';

@Component({
	selector: 'button-banner',
	templateUrl: './button-banner.component.html',
	styleUrls: ['./button-banner.component.scss'],
})
export class ButtonBannerComponent implements OnInit {
	@Output() eventClick = new EventEmitter<any>();
	@Input() disable: boolean;

	constructor() 
	{
		// This is intentional
	}

	ngOnInit(): void 
	{
		// This is intentional
	}

	banner() {
		this.eventClick.emit();
	}
}
