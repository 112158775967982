import { Component, HostBinding, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { appAnimations } from '../../core/animations';
import { AppConfigService } from 'src/app/core/services/config.service';

@Component({
	selector: 'app-content',
	templateUrl: './content.component.html',
	styleUrls: ['./content.component.scss'],
	animations: appAnimations,
})
export class ContentComponent implements OnInit, OnDestroy {
	onSettingsChanged: Subscription;
	appSettings: any;

	@HostBinding('@routerTransitionUp') routeAnimationUp = false;
	@HostBinding('@routerTransitionDown') routeAnimationDown = false;
	@HostBinding('@routerTransitionRight') routeAnimationRight = false;
	@HostBinding('@routerTransitionLeft') routeAnimationLeft = false;
	@HostBinding('@routerTransitionFade') routeAnimationFade = false;

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private appConfig: AppConfigService,
	) {
		this.router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				map(() => this.activatedRoute),
			)
			.subscribe((event) => {
				switch (this.appSettings.routerAnimation) {
					case 'fadeIn':
						this.routeAnimationFade = !this.routeAnimationFade;
						break;
					case 'slideUp':
						this.routeAnimationUp = !this.routeAnimationUp;
						break;
					case 'slideDown':
						this.routeAnimationDown = !this.routeAnimationDown;
						break;
					case 'slideRight':
						this.routeAnimationRight = !this.routeAnimationRight;
						break;
					case 'slideLeft':
						this.routeAnimationLeft = !this.routeAnimationLeft;
						break;
				}
			});

		this.onSettingsChanged = this.appConfig.onSettingsChanged.subscribe(
			(newSettings) => {
				this.appSettings = newSettings;
			},
		);
	}

	ngOnInit() {
		this.router.navigateByUrl("/main/home");
	}

	ngOnDestroy() {
		this.onSettingsChanged.unsubscribe();
	}
}
