import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonSaveComponent } from './button-save/button-save.component';
import { TranslateService } from '@ngx-translate/core';
import { AppPipesModule } from '../pipes/pipes.module';
import { ButtonCancelComponent } from './button-cancel/button-cancel.component';
import { ButtonCloseComponent } from './button-close/button-close.component';
import { ButtonAddComponent } from './button-add/button-add.component';
import { ButtonEditComponent } from './button-edit/button-edit.component';
import { JabilSelectComponent } from './jabil-select/jabil-select.component';
import { JabilInputComponent } from './jabil-input/jabil-input.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { JabilCheckBoxComponent } from './jabil-check-box/jabil-check-box.component';
import { JabilTextAreaComponent } from './jabil-text-area/jabil-text-area.component';
import { FormsModule } from '@angular/forms';
import { ButtonExportComponent } from './button-export/button-export.component';
import { ButtonIconComponent} from './button-icon/button-icon.component';
import { ButtonBannerComponent } from './button-banner/button-banner.component';
import { ButtonProcessComponent } from './button-process/button-process.component';
import { ButtonDetailsComponent } from './button-details/button-details.component';
import { ButtonPolitictsComponent } from './button-politicts/button-politicts.component';
import { ButtonDraftComponent } from './button-draft/button-draft.component';
import { ButtonSearchComponent } from './button-search/button-search.component';
import { ButtonDisableComponent } from './button-disable/button-disable.component';
import { ButtonEnableComponent } from './button-enable/button-enable.component';
import { ButtonApproveComponent } from './button-approve/button-approve.component';
import { ButtonUploadComponent } from './button-upload/button-upload.component';
import { ButtonViewComponent} from './button-view/button-view.component';
import { ButtonUploadSaveComponent } from './button-uploadsave/button-uploadsave.component';
import { ButtonMultiselectComponent} from './button-multiselect/button-multiselect.component';
export function setupTranslateFactory(service: TranslateService): Function {
	return () => service.use('en');
}

@NgModule({
	declarations: [
		ButtonSaveComponent,
		ButtonViewComponent,
		ButtonApproveComponent,
		ButtonUploadComponent,
		ButtonCancelComponent,
		ButtonCloseComponent,
		ButtonAddComponent,
		ButtonEnableComponent,
		ButtonIconComponent,
		ButtonDraftComponent,
		ButtonSearchComponent,
		ButtonPolitictsComponent,
		ButtonDisableComponent,
		ButtonDetailsComponent,
		ButtonProcessComponent,
		ButtonBannerComponent,
		ButtonEditComponent,
		ButtonExportComponent,
		JabilSelectComponent,
		JabilInputComponent,
		JabilCheckBoxComponent,
		JabilTextAreaComponent,
		ButtonUploadSaveComponent,
		ButtonMultiselectComponent,
	],
	imports: [CommonModule, AppPipesModule, NgSelectModule, FormsModule],
	exports: [
		ButtonAddComponent,
		ButtonViewComponent,
		ButtonUploadComponent,
		ButtonApproveComponent,
		ButtonEnableComponent,
		ButtonIconComponent,
		ButtonProcessComponent,
		ButtonPolitictsComponent,
		ButtonSearchComponent,
		ButtonDraftComponent,
		ButtonDisableComponent,
		ButtonBannerComponent,
		ButtonDetailsComponent,
		ButtonEditComponent,
		ButtonSaveComponent,
		ButtonCancelComponent,
		ButtonCloseComponent,
		ButtonExportComponent,
		JabilSelectComponent,
		JabilInputComponent,
		JabilCheckBoxComponent,
		JabilTextAreaComponent,
		ButtonUploadSaveComponent,
		ButtonMultiselectComponent,
	],
	providers: [
		TranslateService,
		{
			provide: APP_INITIALIZER,
			useFactory: setupTranslateFactory,
			deps: [TranslateService],
			multi: true,
		},
	],
})
export class ControlModule {}
