import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
	selector: 'button-upload',
	templateUrl: './button-upload.component.html',
	styleUrls: ['./button-upload.component.scss'],
})
export class ButtonUploadComponent implements OnInit {
	@Input() change = new EventEmitter<any>();
	@Input() type : string;
	@Input() accept : string;
	
	
	constructor() 
	{
		// This is intentional
	}

	ngOnInit(): void 
	{
		// This is intentional
	}

	SelectFile() {
		this.change.emit();
	}
}
