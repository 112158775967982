import { Component, OnInit, Output, EventEmitter,Input } from '@angular/core';

@Component({
	selector: 'button-politicts',
	templateUrl: './button-politicts.component.html',
	styleUrls: ['./button-politicts.component.scss'],
})
export class ButtonPolitictsComponent implements OnInit {
	@Output() eventClick = new EventEmitter<any>();
	@Input() disable: boolean;

	constructor() 
	{
		// This is intentional
	}

	ngOnInit(): void 
	{
		// This is intentional
	}

	politicts() {
		this.eventClick.emit();
	}
}
