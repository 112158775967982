import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
	selector: 'button-save',
	templateUrl: './button-save.component.html',
	styleUrls: ['./button-save.component.scss'],
})
export class ButtonSaveComponent implements OnInit {
	@Output() eventClick = new EventEmitter<any>();
	@Input() disable: boolean;

	constructor() 
	{
		// This is intentional
	}

	ngOnInit(): void 
	{
		// This is intentional
	}

	save() {
		this.eventClick.emit();
	}
}
