import { Component, OnInit, Output, EventEmitter,Input } from '@angular/core';

@Component({
	selector: 'button-draft',
	templateUrl: './button-draft.component.html',
	styleUrls: ['./button-draft.component.scss'],
})
export class ButtonDraftComponent implements OnInit {
	@Output() eventClick = new EventEmitter<any>();
	@Input() disable: boolean;
	constructor() 
	{
		// This is intentional
	}

	ngOnInit(): void 
	{
		// This is intentional
	}

	draft() {
		this.eventClick.emit();
	}
}
