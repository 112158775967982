import { Injectable } from '@angular/core';
import { CognitoService } from 'src/app/core/services/cognito/cognito.service';

@Injectable({ providedIn: 'root' })
export class GeneralServices {
	constructor(private cognitoService: CognitoService) {}

	public ValidateCognito() {
		if (!this.cognitoService.IsAuhtenticated())
			this.cognitoService.logOut();
	}
}
