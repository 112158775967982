import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
	selector: 'button-view',
	templateUrl: './button-view.component.html',
	styles: [],
})
export class ButtonViewComponent implements OnInit {
	@Output() eventClick = new EventEmitter<any>();
	constructor() 
	{
		// This is intentional
	}

	ngOnInit(): void 
	{
		// This is intentional
	}

	view() {
		this.eventClick.emit();
	}
}
