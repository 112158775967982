import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IScreens } from '../../../main/content/sections/Interfaces/IScreens';
import { RolesService } from '../../../main/content/sections/services/roles-service';
import { TransactionResult } from '../../../core/services/interfaces/transaction-result';

@Injectable()
export class NavigationService {
	onNavCollapseToggle = new EventEmitter<any>();
	onNavCollapseToggled = new EventEmitter<any>();
	onNavigationModelChange: BehaviorSubject<any> = new BehaviorSubject({});
	navigationModel: { model: any[] };
	flatNavigation: any[] = [];
	listScreens: IScreens[];

	constructor(private roleService: RolesService) {}

	/**
	 * Get navigation model
	 *
	 * @returns {any[]}
	 */
	getNavigationModel() {
		return this.navigationModel.model;
	}

	/**
	 * Set the navigation model
	 *
	 * @param model
	 */
	setNavigationModel(model) {
		let site = localStorage.getItem('fksite');
		if (site != null && site != 'null') {
			this.roleService.GetScreensByUsername().subscribe(
				(result: TransactionResult<IScreens[]>) => {
					if (result.success) {
						
						this.IsSuccessNavigation(result, model, setNvigationModelConditions);
					}
				},
				(error) => {},
			);
		}

		function setNvigationModelConditions(
			j: number,
			i: number,
			element: IScreens,
		) {
			model.model[0].children[i].children.forEach((level2) => {
				ScreenChildren();
				if (level2.id == element.screen_in_angular) {
					model.model[0].children[i].children.splice(
						model.model[0].children[i].children.indexOf(level2),
						1,
					);
				}
				j = j + 1;
			});
			return j;

			function ScreenChildren() {
				if (model.model[0].children[i].children[j].children) {
					model.model[0].children[i].children[j].children.forEach(
						(level3) => {
							FunctionLevel3(level3);
						});
				}
			}

			function FunctionLevel3(level3: any) {
				if (level3.id == element.screen_in_angular) {
					model.model[0].children[i].children[j].children.splice(
						model.model[0].children[i].children[j].children.indexOf(level3),
						1);
				}
			}
		}
	}

	private IsSuccessNavigation(result: TransactionResult<IScreens[]>, model: any, setNvigationModelConditions: (j: number, i: number, element: IScreens) => number) {
		this.listScreens = result.data;
		let i: number = 0, j: number = 0;
		this.listScreens.forEach((element) => {
			if (element.fk_record_status == 0) {
				i = 0;
				model.model[0].children.forEach(
					(elementModel) => {
						({ i, j } = this.NavigationScreen(model, i, j, setNvigationModelConditions, element, elementModel));
					});
			}
		});

		this.navigationModel = model;
		this.onNavigationModelChange.next(
			this.navigationModel.model);
	}

	private NavigationScreen(model: any, i: number, j: number, setNvigationModelConditions: (j: number, i: number, element: IScreens) => number, element: IScreens, elementModel: any) {
		if (model.model[0].children[i].children) {
			j = 0;
			j = setNvigationModelConditions(
				j,
				i,
				element);
		}
		if (elementModel.id ==
			element.screen_in_angular) {
			model.model[0].children.splice(
				model.model[0].children.indexOf(
					elementModel),
				1);
		}
		i = i + 1;
		return { i, j };
	}

	/*
	 * Function to refract the setNavigationModel function
	 */

	/**
	 * Add new navigation item
	 * to the given location
	 */
	addNavigationItem(location, item) {
		// Parse the location
		const locationArr = location.split('.');

		if (locationArr.length === 0) {
			return;
		}

		// Find the navigation item
		const navItem = this.findNavigationItemById(locationArr);

		// Act according to the item type
		switch (navItem.type) {
			case 'item':
				// Create a children array
				navItem.children = [];

				// Push the item
				navItem.children.push(item);

				// Change the item type to collapsable
				navItem.type = 'collapse';

				break;

			case 'collapse':
				// Push the item
				navItem.children.push(item);

				break;

			case 'group':
				// Push the item
				navItem.children.push(item);

				break;

			default:
				break;
		}
	}

	/**
	 * Get navigation item from
	 * given location
	 *
	 * @param location
	 */
	getNavigationItem(location) {
		// Parse the location
		const locationArr = location.split('.');

		if (locationArr.length === 0) {
			return;
		}

		// Find and return the navigation item
		return this.findNavigationItemById(locationArr);
	}

	/**
	 * Find navigation item by location
	 *
	 * @param location
	 * @param navigation
	 */
	findNavigationItemById(location, navigation?) {
		if (!navigation) {
			navigation = this.navigationModel.model;
		}

		// Iterate through the given navigation
		for (const navItem of navigation) {
			// If the nav item id equals the first location...
			if (navItem.id === location[0]) {
				// If there is more location to look at...
				if (location.length > 1) {
					// Remove the first item of the location
					location.splice(0, 1);

					// Go nested...
					return this.findNavigationItemById(
						location,
						navItem.children,
					);
				}

				// Otherwise just return the nav item
				else {
					return navItem;
				}
			}
		}
	}

	/**
	 * Get flattened navigation array
	 * @param navigationItems
	 * @returns {any[]}
	 */
	getFlatNavigation(navigationItems?) {
		// If navigation items not provided,
		// that means we are running the function
		// for the first time...
		if (!navigationItems) {
			// Reset the flat navigation
			this.flatNavigation = [];

			// Get the entire navigation model
			navigationItems = this.navigationModel.model;
		}

		for (const navItem of navigationItems) {
			if (navItem.type === 'subheader') {
				continue;
			}

			if (navItem.type === 'item') {
				this.flatNavigation.push({
					title: navItem.title,
					type: navItem.type,
					icon: navItem.icon || false,
					url: navItem.url,
				});

				continue;
			}

			if (navItem.type === 'collapse' || navItem.type === 'group') {
				if (navItem.children) {
					this.getFlatNavigation(navItem.children);
				}
			}
		}

		return this.flatNavigation;
	}
}
