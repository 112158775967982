import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from 'src/app/core/services/notification/notification.service';
import { RestCallService } from 'src/app/core/services/rest-call/rest-call.service';
import { TranslateApplicationService } from 'src/app/core/translate/translate-application-service';
import { IEmployee } from '../Interfaces/iemployee';
import { IRoles } from '../Interfaces/IRoles';
import { IEmployeefromsap } from '../Interfaces/IEmployeefromsap';
import { ISites } from '../Interfaces/ISites';
import { GeneralServices } from '../services/general-services';

@Injectable({
	providedIn: 'root',
})
export class EmployeeService extends RestCallService {
	dataToSend: IEmployee = {
		pk_employee: 0,
		employee_name: '',
		workday_id: '',
		nt_account: '',
		email: '',
		fk_role: 0,
		fk_sites: [],
		username: '',
		fk_employee_creator: 0,
		employee_creator_name: '',
		last_updated: new Date(),
		str_last_updated: '',
		fk_employee_updater: 0,
		active: true,
		creation_date: undefined,
		str_creation_date: '',
		check: true,
		username_updater: '',
		site: '',
		fk_site: 0,
		fk_site_logged_user: 0,
	};

	constructor(
		public httpClient: HttpClient,
		private generalServices: GeneralServices,
		public toastr: ToastrService,
		public notificationService: NotificationService,
		public translate: TranslateApplicationService,
	) {
		super(
			httpClient,
			'API_Benefits',
			toastr,
			notificationService,
			translate,
		);
	}

	public GetList(fksite: number) {
		this.generalServices.ValidateCognito();
		this.dataToSend.fk_site = parseInt(localStorage.getItem('fksite'));
		this.dataToSend.fk_site_logged_user = parseInt(
			localStorage.getItem('fksite'),
		);
		return this.post<IEmployee[]>(
			'/api/Employees/GetEmployees',
			this.dataToSend,
		);
	}

	public UpdateEmployeeSite(data: any) {
		
		this.generalServices.ValidateCognito();
		this.dataToSend.pk_employee = data.pkemployee;
		this.dataToSend.workday_id = data.txtWorkdayid;
		this.dataToSend.username = data.username;
		this.dataToSend.employee_name = data.txtEmployeeName;
		this.dataToSend.fk_sites = data.cbSites;
		this.dataToSend.fk_sites = data.cbSite;
		this.dataToSend.email = data.txtEmail == null ? '' : data.txtEmail;
		this.dataToSend.fk_role = data.cbRole == null ? 0 : data.cbRole;
		this.dataToSend.nt_account = data.txtNTAccount;
		this.dataToSend.fk_site_logged_user = parseInt(
			localStorage.getItem('fksite'),
		);
		return this.post<IEmployee>(
			'/api/Employees/UpdateEmployeeSite',
			this.dataToSend,
		);
	}

	public CreateEmployee(data: any) {
		
		this.generalServices.ValidateCognito();
		this.dataToSend.workday_id = data.txtWorkdayid;
		this.dataToSend.username = data.username;
		this.dataToSend.employee_name = data.txtEmployeeName;
		this.dataToSend.fk_sites = data.cbSite;
		this.dataToSend.email = data.txtEmail == null ? '' : data.txtEmail;
		this.dataToSend.fk_role = data.cbRole == null ? 0 : data.cbRole;
		this.dataToSend.nt_account = data.txtNTAccount;
		this.dataToSend.fk_site_logged_user = parseInt(
			localStorage.getItem('fksite'),
		);
		return this.post<IEmployee>(
			'/api/Employees/CreateEmployee',
			this.dataToSend,
		);
	}

	public SetSitesAndRoles(data: any, data2: any) {
		this.generalServices.ValidateCognito();
		this.dataToSend.pk_employee = data.pk_employee;
		this.dataToSend.workday_id = data.txtWorkdayid;
		this.dataToSend.username = data.username;
		this.dataToSend.employee_name = data.txtEmployeeName;
		this.dataToSend.fk_sites = data2;
		this.dataToSend.email = data.email == null ? '' : data.email;
		this.dataToSend.fk_role = data.fk_role == null ? 0 : data.fk_role;
		this.dataToSend.nt_account = data.nt_account;
		this.dataToSend.fk_site_logged_user = parseInt(
			localStorage.getItem('fksite'),
		);
		return this.post<IEmployee>(
			'/api/Employees/setSitesAndRoles',
			this.dataToSend,
		);
	}

	public VerifyEmailInEmployee(data: any) {
		this.generalServices.ValidateCognito();
		this.dataToSend.fk_site_logged_user = parseInt(
			localStorage.getItem('fksite'),
		);
		return this.post<IEmployee>(
			'/api/Employees/VerifyEmailInEmployee',
			this.dataToSend,
		);
	}

	public ChangeUserStatus(data: any) {
		this.generalServices.ValidateCognito();
		this.dataToSend.pk_employee = data.pk_employee;
		this.dataToSend.employee_name = data.employee_name;
		this.dataToSend.workday_id = data.workday_id;
		this.dataToSend.nt_account = data.nt_account;
		this.dataToSend.fk_role = data.fk_role;
		this.dataToSend.active = data.active;
		this.dataToSend.check = data.check;
		this.dataToSend.fk_site = parseInt(localStorage.getItem('fksite'));
		this.dataToSend.fk_site_logged_user = parseInt(
			localStorage.getItem('fksite'),
		);
		return this.post('/api/Employees/ChangeUserStatus', this.dataToSend);
	}

	public IsAdmin() {
		this.generalServices.ValidateCognito();
		return this.post<IRoles>('/api/Employees/IsAdmin', this.dataToSend);
	}

	public GetUserInfo(workdayid: any) {
		this.generalServices.ValidateCognito();
		this.dataToSend.username = workdayid;
		this.dataToSend.workday_id = workdayid;
		return this.post<IEmployeefromsap[]>(
			'/api/Employees/GetUserInfo',
			this.dataToSend,
		);
	}

	public GetSitesByUser(data: any) {
		this.generalServices.ValidateCognito();
		this.dataToSend.fk_site = parseInt(localStorage.getItem('fksite'));
		this.dataToSend.username = data.username;
		return this.post<ISites[]>(
			'/api/Employees/GetSitesByUser',
			this.dataToSend,
		);
	}

	public GetUsername() {
		this.generalServices.ValidateCognito();
		this.dataToSend.fk_site = parseInt(localStorage.getItem('fksite'));
		return this.post<IEmployee[]>(
			'/api/Employees/GetUsername',
			this.dataToSend,
		);
	}
}
