import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
	selector: 'button-uploadsave',
	templateUrl: './button-uploadsave.component.html',
})
export class ButtonUploadSaveComponent implements OnInit {
	@Output() eventClick = new EventEmitter<any>();
	@Input() disable: boolean;

	constructor() 
	{
		// This is intentional
	}

	ngOnInit(): void 
	{
		// This is intentional
	}

	Upload() {
		this.eventClick.emit();
	}
}
