import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { TransactionResult } from 'src/app/core/services/interfaces/transaction-result';
import { PropertiesService } from '../content/sections/services/properties-service';
import { IProperties } from '../content/sections/Interfaces/IProperties';
import { EnvironmentsService } from '../content/sections/services/environments-services';
import { IEnvironments } from '../content/sections/Interfaces/IEnvironments';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
	APP_VERSION: string;
	ENVIRONMENT: string;

	public production: boolean;
	constructor(
		private propertiesService: PropertiesService,
		private environmentsService: EnvironmentsService,
	) {
		this.production = environment.production;
	}

	ngOnInit() {
		this.getAppVersion();
		this.getEnvironment();
	}

	getAppVersion() {
		this.propertiesService
			.GetPropertyByName('APP_VERSION')
			.subscribe((result: TransactionResult<IProperties[]>) => {
				if (result.success) {
					this.APP_VERSION = result.data[0].property_value;
				}
			});
	}
	getEnvironment() {
		this.environmentsService
			.GetActiveEnvironment()
			.subscribe((result: TransactionResult<IEnvironments[]>) => {
				if (result.success) {
					this.ENVIRONMENT = result.data[0].description;
				}
			});
	}
}
