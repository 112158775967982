import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'button-enable',
	templateUrl: './button-enable.component.html',
	styleUrls: ['./button-enable.component.scss'],
})
export class ButtonEnableComponent implements OnInit {
	@Output() eventClick = new EventEmitter<any>();

	constructor() 
	{
		// This is intentional
	}

	ngOnInit(): void 
	{
		// This is intentional
	}

	enable() {
		this.eventClick.emit();
	}
}
